import React, { Component } from "react"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing-3"
import { Link } from "gatsby"
import Layout from "../layouts"
import { decodeHTMLEntities } from "../utils/decodeHTMLEntities"
import * as css from "./ExhibitorTemplate.module.scss"
import GlobalMenuButton from "../components/menu/GlobalMenuButton"
import CallExhibitorModalOpen from "../components/exhibitor/CallExhibitorModalOpen"
import BackToStageButton from "./components/BackToStageButton"
import ExhibitorDetailWrap from "../components/exhibitor/ExhibitorDetailWrap"

class ExhibitorTemplate extends Component {
  constructor(modal, closeTo) {
    super()

    this.state = {
      context: modal.pageContext,
    }
  }

  render() {
    const exhibitor = this.state.context

    const getElBackButton = closeTo => {
      return (
        <Link
          to={closeTo ? closeTo : "/en"}
          className={`TextBtn TextBtn--back`}
        >
          <span>Leave the booth</span>
        </Link>
      )
    }

    return (
      <ModalRoutingContext.Consumer>
        {({ modal, closeTo }) => (
          <div>
            {modal ? (
              <div data-motion="up-inner-1">
                <CallExhibitorModalOpen />
                <div className={css.head}>
                  {getElBackButton(closeTo)}
                  <GlobalMenuButton />
                </div>
                <ExhibitorDetailWrap exhibitor={exhibitor} lang={`en`} />
              </div>
            ) : (
              <Layout
                lang="en"
                location={this.props.location}
                title={`${decodeHTMLEntities(exhibitor.name_en)} | EXHIBITOR`}
                description={decodeHTMLEntities(exhibitor.profile)}
                ogImage={exhibitor.table[0].src}
              >
                <div className={css.head}>
                  <BackToStageButton
                    stage={`${exhibitor.stage.toLowerCase()}`}
                    lang={`en`}
                  />
                  <GlobalMenuButton />
                </div>
                <ExhibitorDetailWrap exhibitor={exhibitor} lang={`en`} />
              </Layout>
            )}
          </div>
        )}
      </ModalRoutingContext.Consumer>
    )
  }
}

export default ExhibitorTemplate
